// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type DeleteIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let DeleteIcon: NessieFunctionComponent<DeleteIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "DeleteIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: DeleteIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.3 3C7.86406 3 6.7 4.16406 6.7 5.6V7H4C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9H5.3L5.7658 16.4529C5.86637 18.062 6.0612 18.6365 6.4005 19.2129C6.7398 19.7892 7.21537 20.236 7.81176 20.5387C8.40815 20.8414 8.99375 21 10.606 21H13.494C15.1062 21 15.6918 20.8414 16.2882 20.5387C16.8846 20.236 17.3602 19.7892 17.6995 19.2129C18.0388 18.6365 18.2336 18.062 18.3342 16.4529L18.8 9H20C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7H17.3V5.6C17.3 4.16406 16.1359 3 14.7 3H9.3ZM9.1 6C9.1 5.44772 9.54771 5 10.1 5H13.9C14.4523 5 14.9 5.44772 14.9 6V7H9.1V6ZM8.4 10C8.4 9.44772 8.84772 9 9.4 9C9.95229 9 10.4 9.44771 10.4 10V17.5C10.4 18.0523 9.95229 18.5 9.4 18.5C8.84772 18.5 8.4 18.0523 8.4 17.5V10ZM13.6 10C13.6 9.44772 14.0477 9 14.6 9C15.1523 9 15.6 9.44771 15.6 10V17.5C15.6 18.0523 15.1523 18.5 14.6 18.5C14.0477 18.5 13.6 18.0523 13.6 17.5V10Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
DeleteIcon.nessiePropTypes = iconNessiePropTypes;
DeleteIcon = nessiePropCheckerWrapper(DeleteIcon);
export default DeleteIcon;
