import { css } from "@emotion/react"; // eslint-disable-line no-restricted-imports
import {
  Root,
  Trigger,
  Portal,
  Content,
  Viewport,
  Item,
  Value,
  Group,
  ItemText,
  ItemIndicator,
} from "@radix-ui/react-select";
import uniqueId from "lodash/uniqueId";
import { useState, ComponentProps } from "react";
import { LabelFormElement } from "../..";
import { CheckmarkIcon, ChevronDownIcon } from "../../nessie/icons";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { GlobalCSS } from "../../nessie/stylingLib";
import { ScrollContainer } from "./ScrollContainer";
import { textFieldStyles } from "./TextField";
import { useNavigate } from "react-router-dom";

export type ComboBoxOptionType = { label: string; value: string; to?: string };
export interface ComboBoxProps<T extends Readonly<ComboBoxOptionType[]>> {
  options: T;
  labelText: string;
  labelPosition?: "top" | "hidden";
  selectedValue?: ComboBoxOptionType["value"];
  onSelectOption?: (option: T[number]) => void;
  className?: string;
  placeholderClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  translate?: "no" | "yes";
  ContentProps?: Omit<ComponentProps<typeof Content>, "position" | "sideOffset">;
  icon?: JSX.Element;
  portalContainer?: HTMLElement | null;
  contentSide?: "top" | "bottom" | "left" | "right";
}

export function ComboBox<T extends Readonly<ComboBoxOptionType[]>>({
  options,
  labelText,
  labelPosition = "top",
  selectedValue,
  onSelectOption,
  className,
  placeholderClassName,
  disabled,
  placeholder,
  translate,
  ContentProps,
  icon,
  portalContainer,
  contentSide = "bottom",
}: ComboBoxProps<T>) {
  const navigate = useNavigate();
  const [baseId] = useState(() => uniqueId("ComboBoxOption"));
  const [isOpen, setIsOpen] = useState(false);
  const handleSelection = (selected: string) => {
    const filteredOptions = options.filter((option) => selected === option.value);
    const selectedOption = filteredOptions[0];
    if (onSelectOption) {
      return onSelectOption(selectedOption);
    }
    if (selectedOption.to) {
      return navigate(selectedOption.to);
    }
    throw new Error("No onSelectOption or to prop found in selected option");
  };

  return (
    // the onKeyDown is needed to close the dropdown when the escape key is pressed when inside a modal.
    // radix and react modal seem to be conflicting with each other.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      translate={translate}
      className={className}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "xs",
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.stopPropagation();
          setIsOpen(false);
        }
      }}
    >
      <GlobalCSS
        styles={css`
          [data-radix-popper-content-wrapper] {
            width: var(--radix-popper-anchor-width);
            z-index: 1000 !important;
          }
        `}
      />
      <Root
        onValueChange={handleSelection}
        value={selectedValue}
        disabled={disabled}
        open={isOpen}
        onOpenChange={(e) => {
          if (e) setIsOpen(true);
          else setIsOpen(false);
        }}
      >
        {labelPosition === "hidden" ? null : <LabelFormElement htmlFor={baseId} labelText={labelText} />}

        <Trigger aria-label={labelText} id={baseId} sx={comboInputStyles}>
          <div className={placeholderClassName}>
            {icon}
            <Value placeholder={placeholder} />
          </div>
          <ChevronDownIcon size="s" color="dt_content_tertiary" />
        </Trigger>
        <Portal container={portalContainer}>
          <Content {...ContentProps} position="popper" sideOffset={5} side={contentSide} aria-label={labelText}>
            <ScrollContainer
              maxHeight={300}
              sx={{
                borderRadius: "dt_radius_s",
                border: "dt_card",
                backgroundColor: "dt_background_primary",
              }}
            >
              <Viewport>
                <Group>
                  {options.map((option) => (
                    <Item key={option.value} value={option.value} sx={comboOptionStyles}>
                      <ItemText>{option.label}</ItemText>
                      <ItemIndicator sx={{ height: 24, marginRight: "xs" }}>
                        {<CheckmarkIcon size="m" color="dt_content_accent" />}
                      </ItemIndicator>
                    </Item>
                  ))}
                </Group>
              </Viewport>
            </ScrollContainer>
          </Content>
        </Portal>
      </Root>
    </div>
  );
}

const comboInputStyles: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  backgroundColor: "dt_background_primary",
  ...textFieldStyles,
  "&[aria-expanded='true']": {
    borderWidth: "2px",
    borderColor: "dt_border_active",
  },
  "&[data-placeholder]": {
    textTransform: "capitalize",
    color: "dt_content_tertiary",
  },
  "> div:nth-of-type(1)": {
    display: "flex",
    alignItems: "center",
    gap: "xs",
  },
  ":focus": {
    borderColor: "dt_border_active",
    outline: "none",
  },
};

const comboOptionStyles: ThemeUIStyleObject = {
  width: "90%",
  outline: "none",
  padding: "dt_s",
  margin: "dt_xxs",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderColor: "transparent",
  fontSize: "18px",
  lineHeight: "24px",
  color: "dt_content_primary",
  backgroundColor: "dt_background_primary",
  borderRadius: "dt_radius_s",
  userSelect: "none",

  ":hover": {
    backgroundColor: "dt_background_accent",
  },
  "&[aria-selected=true], &[data-state='checked']": {
    backgroundColor: "dt_background_accent",
    color: "dt_content_primary",
    borderColor: "dt_content_tertiary",
  },
};
