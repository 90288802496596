// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PhotoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PhotoIcon: NessieFunctionComponent<PhotoIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PhotoIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PhotoIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 6H4L4 18H20V6ZM4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM7.50044 11C8.329 11 9 10.3274 9 9.5C9 8.67168 8.329 8 7.50044 8C6.67188 8 6 8.67168 6 9.5C6 10.3274 6.67188 11 7.50044 11ZM6.23235 14.6588L7.10868 13.415C7.55827 12.7768 8.49089 12.7391 8.99062 13.3387C9.49603 13.9452 10.4417 13.8984 10.8847 13.2449L12.9772 10.1584C13.4611 9.4448 14.5172 9.45907 14.9816 10.1855L17.8697 14.7036C18.3803 15.5024 17.8066 16.5499 16.8586 16.5499H7.21333C6.24084 16.5499 5.67224 15.4538 6.23235 14.6588Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PhotoIcon.nessiePropTypes = iconNessiePropTypes;
PhotoIcon = nessiePropCheckerWrapper(PhotoIcon);
export default PhotoIcon;
