import { useMemo } from "react";
import logEvent from "../../utils/logEvent";
import { AnnouncementModal } from "../modals";
import formatNotificationUrl from "./formatUrl";
import { NotificationWithSecondaryView, StoryPost } from "./types";

type NotificationModalProps = {
  notification: NotificationWithSecondaryView;
  openStoryComposeArea?: (storyPost: StoryPost) => void;
  hide: () => void;
};

const NotificationModal = ({ notification, openStoryComposeArea, hide }: NotificationModalProps): JSX.Element => {
  const modal = notification.secondaryView;
  const { destination, storyPost } = notification;
  const url = destination ? destination.web : null;
  const cta = modal.cta || "dojo.common:class_story.got_it";

  const onClickAction = useMemo(() => {
    if (url) {
      const { newUrl, target } = formatNotificationUrl(url);
      if (target === "") {
        return () => {
          window.location.assign(newUrl);
          hide();
        };
      } else {
        return () => window.open(newUrl);
      }
    } else if (storyPost && openStoryComposeArea) {
      return () => {
        openStoryComposeArea(storyPost);
        hide();
      };
    } else {
      return hide;
    }
  }, [hide, openStoryComposeArea, storyPost, url]);

  const _logNotificationCTAClickEvent = () => {
    const isClassDojoNotification = notification && notification.actor && notification.actor.type === "dojo";
    if (isClassDojoNotification) {
      logEvent({ eventName: "web.in_app_notification.clickCTA", eventValue: notification._id });
    }
  };

  return (
    <AnnouncementModal
      data-name="notification_modal:announcement_modal"
      header={modal.header}
      image={modal.image}
      text={modal.text}
      cta={{
        text: cta,
        props: {
          "data-name": `${modal.text}-cta}`,
          onClick: () => {
            _logNotificationCTAClickEvent();
            onClickAction();
          },
        },
      }}
      close={hide}
      showCloseButton={true}
    />
  );
};

export default NotificationModal;
