/* eslint-disable jsx-a11y/no-static-element-interactions */
import maxBy from "lodash/maxBy";
import { MouseEvent, useEffect, useRef, useState } from "react";
import * as React from "react";
import { NumberBadge } from "../../nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { TranslatedString } from "../../pods/i18n/translate";
import logEvent from "../../utils/logEvent";
import { translate } from "../../utils/translate";
import T from "../misc/T";
import PopoverTrigger from "../overlay/PopoverTrigger";
import NotificationItem from "./NotificationItem";
import NotificationModal from "./NotificationModal";
import { Notification, NotificationWithSecondaryView } from "./types";
import { Image } from "../misc/Image";

export type JoinClassRequest = {
  teacher: { _id: string; title?: string; lastName?: string; avatarURL?: string };
  class: { _id: string; name: string };
  lastUpdated?: string;
  acceptJoinClassRequest: React.ComponentPropsWithoutRef<typeof NotificationItem>["acceptAction"];
  rejectJoinClassRequest: React.ComponentPropsWithoutRef<typeof NotificationItem>["declineAction"];
};

export type NotificationCenterProps = {
  purple?: boolean;
  notifications?: Notification[];
  parentRequestCount?: number;
  studentRequestCount?: number;
  schoolTeacherRequestCount?: number;
  joinClassRequests?: JoinClassRequest[];
  openStoryComposeArea?: React.ComponentPropsWithoutRef<typeof NotificationModal>["openStoryComposeArea"];
  onClickRequests?: (e: MouseEvent) => void;
  lastRequest?: string;
  onClickSchoolRequests?: (e: MouseEvent) => void;
  lastSchoolRequest?: string;
  markRead: (_id: string, createdAt: string) => void;
};

const NotificationCenter = ({
  purple,
  notifications,
  parentRequestCount,
  studentRequestCount,
  schoolTeacherRequestCount,
  joinClassRequests,
  openStoryComposeArea,
  onClickRequests,
  lastRequest,
  onClickSchoolRequests,
  lastSchoolRequest,
  markRead,
}: NotificationCenterProps): JSX.Element => {
  const unreadNotificationIds = useRef<string[]>([]);

  const logReceivedUnreadNotifications = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      logEvent({ eventName: "web.in_app_notification.receive", eventValue: id });
    });
  };

  const logViewUnreadNotificationsEvent = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      logEvent({ eventName: "web.in_app_notification.viewInList", eventValue: id });
    });
  };

  useEffect(() => {
    const receivedUnreadIds =
      (notifications &&
        notifications.filter(isUnreadClassDojoNotification).map(
          (notification) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            notification._id!,
        )) ||
      [];
    receivedUnreadIds.sort();

    if (
      unreadNotificationIds.current.length !== receivedUnreadIds.length ||
      unreadNotificationIds.current.some((x) => !receivedUnreadIds.includes(x))
    ) {
      unreadNotificationIds.current = receivedUnreadIds;
      logReceivedUnreadNotifications(unreadNotificationIds.current);
    }
  }, [notifications]);

  const onOpen = () => {
    if (!notifications) return;
    logViewUnreadNotificationsEvent(unreadNotificationIds.current);

    const latest = maxBy(notifications, (n) => n.createdAt && Date.parse(n.createdAt));
    if (!latest || !latest._id || !latest.createdAt) return;
    markRead(latest._id, latest.createdAt);
  };

  // This needs to be controlled here and not by the individual notifications,
  // because otherwise withClickOutside will close the modal at weird times.
  const [showingSecondaryViewFor, setShowingSecondaryViewFor] = useState<string | undefined>(undefined);
  const notificationWithSecondaryView = findNotificationWithSecondaryView(notifications, showingSecondaryViewFor);

  const showSecondaryView = (notificationId: string) => setShowingSecondaryViewFor(notificationId);

  const hideSecondaryView = () => setShowingSecondaryViewFor(undefined);

  const ariaLabelNotifications = !notifications?.length
    ? translate("dojo.common:notification_center:no_notification", "There are no notifications")
    : notifications?.length === 1
      ? translate("dojo.common:notification_center:one_notification", "There is 1 notification")
      : translate("dojo.common:notification_center:multiple_notification", "There are __count__ notifications", {
          count: notifications!.length,
        });

  const contents = (
    <NotificationCenterContents
      notifications={notifications}
      parentRequestCount={parentRequestCount}
      studentRequestCount={studentRequestCount}
      onClickRequests={onClickRequests}
      joinClassRequests={joinClassRequests}
      lastRequest={lastRequest}
      schoolTeacherRequestCount={schoolTeacherRequestCount}
      onClickSchoolRequests={onClickSchoolRequests}
      lastSchoolRequest={lastSchoolRequest}
      openStoryComposeArea={openStoryComposeArea}
      showSecondaryView={showSecondaryView}
    />
  );

  return (
    <PopoverTrigger
      popoverContent={contents}
      popoverCaretOffset="60%"
      popoverCaretSize="1rem"
      popoverPadding="0"
      popoverGutter={"dt_s"}
      popoverMaxWidth={MENU_WIDTH}
      popoverPosition="bottom"
      aria-label={ariaLabelNotifications}
      popoverTextColor={"dt_content_primary"}
      onOpen={onOpen}
      data-name="notificationCenterButton"
    >
      <div sx={styles.container}>
        <Image alt="" src={purple ? BELL_PURPLE_ICON : BELL_ICON} sx={styles.bell} />
        <NotificationBadge
          parentRequestCount={parentRequestCount}
          studentRequestCount={studentRequestCount}
          schoolTeacherRequestCount={schoolTeacherRequestCount}
          joinClassRequests={joinClassRequests}
          notifications={notifications}
        />
      </div>
      {notificationWithSecondaryView && (
        <NotificationModal
          notification={notificationWithSecondaryView}
          hide={hideSecondaryView}
          openStoryComposeArea={openStoryComposeArea}
        />
      )}
    </PopoverTrigger>
  );
};

export default NotificationCenter;

type NotificationCenterContentsProps = Pick<
  NotificationCenterProps,
  | "notifications"
  | "parentRequestCount"
  | "studentRequestCount"
  | "onClickRequests"
  | "joinClassRequests"
  | "lastRequest"
  | "schoolTeacherRequestCount"
  | "onClickSchoolRequests"
  | "lastSchoolRequest"
  | "openStoryComposeArea"
> & {
  showSecondaryView: (notificationId: string) => void;
};
const NotificationCenterContents = ({
  notifications,
  parentRequestCount,
  studentRequestCount,
  onClickRequests,
  joinClassRequests,
  lastRequest,
  schoolTeacherRequestCount,
  onClickSchoolRequests,
  lastSchoolRequest,
  openStoryComposeArea,
  showSecondaryView,
}: NotificationCenterContentsProps) => {
  if (!notifications) {
    // loading state
    return (
      <div
        data-name="notificationCenterLoading"
        sx={{ ...styles.dropdownContainer, ...styles.dropdownContainerEmpty }}
        onClick={(e) => e.stopPropagation()}
      >
        <T sx={styles.dropdownHeader} str="dojo.common:notifications.notifications" fallback="Notifications" />
        <div sx={styles.dropdownEmptyContents}>
          <T str="dojo.common:notifications.loading" fallback="Loading notifications..." />
        </div>
      </div>
    );
  } else if (notifications.length === 0) {
    // no notifications
    return (
      <div data-name="notificationCenterEmpty" sx={{ ...styles.dropdownContainer, ...styles.dropdownContainerEmpty }}>
        <ConnectionRequestNotification
          parentRequestCount={parentRequestCount}
          studentRequestCount={studentRequestCount}
          onClickRequests={onClickRequests}
          joinClassRequests={joinClassRequests}
          lastRequest={lastRequest}
        />
        <SchoolTeacherRequestsNotification
          schoolTeacherRequestCount={schoolTeacherRequestCount}
          onClickSchoolRequests={onClickSchoolRequests}
          lastSchoolRequest={lastSchoolRequest}
        />
        <div onClick={(e) => e.stopPropagation()}>
          <T sx={styles.dropdownHeader} str="dojo.common:notifications.notifications" fallback="Notifications" />
          <div sx={styles.dropdownEmptyContents}>
            <T str="dojo.common:notifications.no_recent" fallback="No recent notifications" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div sx={styles.dropdownContainer}>
        <ConnectionRequestNotification
          parentRequestCount={parentRequestCount}
          studentRequestCount={studentRequestCount}
          onClickRequests={onClickRequests}
          joinClassRequests={joinClassRequests}
          lastRequest={lastRequest}
        />
        <SchoolTeacherRequestsNotification
          schoolTeacherRequestCount={schoolTeacherRequestCount}
          onClickSchoolRequests={onClickSchoolRequests}
          lastSchoolRequest={lastSchoolRequest}
        />
        <T sx={styles.dropdownHeader} str="dojo.common:notifications.notifications" fallback="Notifications" />
        {notifications.map((notification, i) => (
          <NotificationItem
            key={notification._id}
            notification={notification}
            isFirst={i === 0}
            showSecondaryView={showSecondaryView}
            openStoryComposeArea={openStoryComposeArea}
          />
        ))}
      </div>
    );
  }
};

type NotificationBadgeProps = Pick<
  NotificationCenterProps,
  "parentRequestCount" | "studentRequestCount" | "schoolTeacherRequestCount" | "joinClassRequests" | "notifications"
>;

const NotificationBadge = ({
  parentRequestCount = 0,
  studentRequestCount = 0,
  schoolTeacherRequestCount = 0,
  joinClassRequests = [],
  notifications,
}: NotificationBadgeProps) => {
  const requestCount = parentRequestCount + studentRequestCount + schoolTeacherRequestCount + joinClassRequests.length;
  const count = (notifications?.filter((n) => !n.read).length || 0) + requestCount;
  if (count > 0) {
    return (
      <div sx={{ ...styles.badge, ...styles.unreadBadge }}>
        <NumberBadge data-name="badge" value={count} size="xs" kind="danger" noBorder />
      </div>
    );
  }
  return null;
};

type ConnectionRequestNotification = Pick<
  NotificationCenterProps,
  "parentRequestCount" | "studentRequestCount" | "onClickRequests" | "joinClassRequests" | "lastRequest"
>;

const ConnectionRequestNotification = ({
  parentRequestCount = 0,
  studentRequestCount = 0,
  onClickRequests,
  joinClassRequests,
  lastRequest,
}: ConnectionRequestNotification) => {
  const displaySchoolTeacherConnections = onClickRequests && parentRequestCount + studentRequestCount > 0;
  const displayJoinClassRequests = joinClassRequests && joinClassRequests.length;

  if (!displaySchoolTeacherConnections && !displayJoinClassRequests) return null;

  return (
    <div>
      <T
        sx={styles.dropdownHeader}
        // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
        str="dojo.common:pending_connection_requests"
        fallback="Pending connection requests"
      />
      <StudentParentConnectionRequests
        parentRequestCount={parentRequestCount}
        studentRequestCount={studentRequestCount}
        onClickRequests={onClickRequests}
        lastRequest={lastRequest}
      />
      <JoinClassRequests joinClassRequests={joinClassRequests} />
    </div>
  );
};

type StudentParentConnectionRequests = Pick<
  NotificationCenterProps,
  "parentRequestCount" | "studentRequestCount" | "onClickRequests" | "lastRequest"
>;

const StudentParentConnectionRequests = ({
  parentRequestCount = 0,
  studentRequestCount = 0,
  onClickRequests,
  lastRequest = "",
}: StudentParentConnectionRequests) => {
  if (onClickRequests && parentRequestCount + studentRequestCount > 0) {
    let notification;
    if (studentRequestCount > 0) {
      const text = generateRequestString(parentRequestCount, studentRequestCount);
      notification = {
        content: { text },
        actor: { type: "connectionRequests" },
        createdAt: lastRequest,
      };
    } else {
      // to preserve existing translations
      const text =
        parentRequestCount === 1
          ? translate("dojo.common:notifications.parent_requests.content_one", { count: "1" })
          : translate("dojo.common:notifications.parent_requests.content_multi", { count: String(parentRequestCount) });
      notification = {
        content: { text },
        actor: { type: "connectionRequests" },
        createdAt: lastRequest,
      };
    }
    const requestIcon = (
      <div sx={styles.requestIcon}>
        <Image alt="" height={50} src={REQUEST_ICON} />
        <div sx={{ ...styles.parentBadge, ...styles.badge }}>
          <NumberBadge
            data-name="badge"
            value={parentRequestCount + studentRequestCount}
            size="xs"
            kind="danger"
            noBorder
          />
        </div>
      </div>
    );
    return (
      <NotificationItem
        key="requests"
        notification={notification}
        isFirst={true}
        customOnClick={onClickRequests}
        customAvatar={requestIcon}
      />
    );
  }

  return null;
};

// for classroom owners to know about requests to join their class
type JoinClassRequestsProps = Pick<NotificationCenterProps, "joinClassRequests">;

const JoinClassRequests = ({ joinClassRequests = [] }: JoinClassRequestsProps) => {
  if (!joinClassRequests.length) {
    return null;
  }

  return (
    <>
      {joinClassRequests.map((joinClassRequest) => {
        const {
          teacher,
          class: classroom,
          lastUpdated,
          acceptJoinClassRequest,
          rejectJoinClassRequest,
        } = joinClassRequest;

        if (!acceptJoinClassRequest || !rejectJoinClassRequest) return null;

        const requestIcon = (
          <div sx={styles.requestIcon}>
            <img alt="" height={50} src={teacher.avatarURL} />
          </div>
        );
        const notification = {
          content: {
            text: translate({
              str: "dojo.common:notifications.join_class_requests.content",
              subs: { teacherTitle: teacher.title!, teacherLastName: teacher.lastName!, className: classroom.name },
              fallback: "__teacherTitle__ __teacherLastName__ is requesting to join __className__",
            }),
          },
          actor: { type: "connectionRequests" },
          createdAt: lastUpdated,
        };
        return (
          <NotificationItem
            key={`join-class-requests-${classroom._id}-${teacher._id}`}
            notification={notification}
            isFirst={true}
            customAvatar={requestIcon}
            acceptAction={acceptJoinClassRequest}
            declineAction={rejectJoinClassRequest}
          />
        );
      })}
    </>
  );
};

// for mentors and school leaders to know about pending teachers
type SchoolTeacherRequestsNotificationProps = Pick<
  NotificationCenterProps,
  "schoolTeacherRequestCount" | "onClickSchoolRequests" | "lastSchoolRequest"
>;

const SchoolTeacherRequestsNotification = ({
  schoolTeacherRequestCount = 0,
  onClickSchoolRequests,
  lastSchoolRequest,
}: SchoolTeacherRequestsNotificationProps) => {
  if (onClickSchoolRequests && schoolTeacherRequestCount > 0) {
    const requestIcon = (
      <div sx={styles.requestIcon}>
        <Image alt="" height={50} src={SCHOOL_REQUEST_ICON} />
        <div sx={{ ...styles.parentBadge, ...styles.badge }}>
          <NumberBadge data-name="badge" value={schoolTeacherRequestCount} size="xs" kind="danger" noBorder />
        </div>
      </div>
    );
    const notification = {
      content: {
        text: translate({
          str: "dojo.common:notifications.school_teacher_request_join.content",
          subs: { schoolTeacherRequestCount: String(schoolTeacherRequestCount) },
          fallback: "__schoolTeacherRequestCount__ teacher(s) want to join your school.",
        }),
      },
      actor: { type: "connectionRequests" },
      createdAt: lastSchoolRequest,
    };
    return (
      <div>
        <T sx={styles.dropdownHeader} str="dojo.common:pending_school_requests" fallback="Pending school requests" />
        <NotificationItem
          key="requests"
          notification={notification}
          isFirst={true}
          customOnClick={onClickSchoolRequests}
          customAvatar={requestIcon}
        />
      </div>
    );
  }

  return null;
};

function hasSecondaryView(notification?: Notification): notification is NotificationWithSecondaryView {
  return !!notification && !!notification.secondaryView;
}

const findNotificationWithSecondaryView = (
  notifications: Notification[] | undefined,
  notificationId: string | undefined,
): NotificationWithSecondaryView | undefined => {
  if (!notifications || !notificationId) return;

  const notification = notifications.find((n) => n._id === notificationId);
  return hasSecondaryView(notification) ? notification : undefined;
};

// Check if is a notification created by our marketing team.
function isClassDojoNotification(notification: Notification) {
  return notification && !!notification._id && notification.actor && notification.actor.type === "dojo";
}

const isUnreadClassDojoNotification = (notification: Notification) =>
  isClassDojoNotification(notification) && notification.read === false;

function generateRequestString(parentCount: number, studentCount: number): TranslatedString {
  if (parentCount === 0 && studentCount === 1) {
    return translate({
      str: "dojo.common:notifications.connection_requests.single_student",
      fallback: "You have __studentCount__ student connection request to review.",
      subs: { studentCount: String(studentCount) },
    });
  } else if (parentCount === 1 && studentCount === 0) {
    return translate({
      str: "dojo.common:notifications.connection_requests.single_parent",
      fallback: "You have __parentCount__ parent connection request to review.",
      subs: { parentCount: String(parentCount) },
    });
  } else if (parentCount === 0 && studentCount > 1) {
    return translate({
      str: "dojo.common:notifications.connection_requests.multiple_students",
      fallback: "You have __studentCount__ student connection requests to review.",
      subs: { studentCount: String(studentCount) },
    });
  } else if (parentCount > 1 && studentCount === 0) {
    return translate({
      str: "dojo.common:notifications.connection_requests.multiple_parents",
      fallback: "You have __parentCount__ parent connection requests to review.",
      subs: { parentCount: String(parentCount) },
    });
  } else if (parentCount === 1 && studentCount === 1) {
    return translate({
      str: "dojo.common:notifications.connection_requests.single_student_single_parent",
      fallback:
        "You have __studentCount__ student connection request and __parentCount__ parent connection request to review.",
      subs: { parentCount: String(parentCount), studentCount: String(studentCount) },
    });
  } else if (parentCount === 1 && studentCount > 1) {
    return translate({
      str: "dojo.common:notifications.connection_requests.multiple_students_single_parent",
      fallback:
        "You have __studentCount__ student connection requests and __parentCount__ parent connection request to review.",
      subs: { parentCount: String(parentCount), studentCount: String(studentCount) },
    });
  } else if (parentCount > 1 && studentCount === 1) {
    return translate({
      str: "dojo.common:notifications.connection_requests.single_student_multiple_parents",
      fallback:
        "You have __studentCount__ student connection request and __parentCount__ parent connection requests to review.",
      subs: { parentCount: String(parentCount), studentCount: String(studentCount) },
    });
  } else {
    return translate({
      str: "dojo.common:notifications.connection_requests.multiple_students_multiple_parents",
      fallback:
        "You have __studentCount__ student connection requests and __parentCount__ parent connection requests to review.",
      subs: { parentCount: String(parentCount), studentCount: String(studentCount) },
    });
  }
}

const MENU_WIDTH = "36rem";

const BELL_ICON = "components/notification_center/bell.png";
const BELL_PURPLE_ICON = "components/notification_center/bell_purple.png";
const REQUEST_ICON = "components/notification_center/pending-parents.png";
const SCHOOL_REQUEST_ICON = "components/notification_center/pending-teachers.png";

const styles: Record<string, ThemeUIStyleObject> = {
  outerContainer: {
    position: "relative",
  },
  container: {
    lineHeight: 0, // otherwise there's weird padding under the bell.
  },
  bell: {
    height: "2.4rem",
    cursor: "pointer",
  },
  badge: {
    position: "absolute",
    left: "100%",
    transform: "translateX(-65%)",
    lineHeight: 1.2,
    cursor: "pointer",
  },
  unreadBadge: {
    top: "-0.3rem",
  },
  parentBadge: {
    bottom: "0rem",
  },
  dropdownContainer: {
    width: MENU_WIDTH,
    maxHeight: "48rem",
    overflowY: "auto",
    fontSize: "1.4rem",
  },
  dropdownContainerEmpty: {
    minHeight: "12.8rem",
  },
  dropdownHeader: {
    padding: RAW_cssValue("0.8rem 1.2rem"),
    display: "block",
    fontWeight: 600,
    fontSize: "1.4rem",
  },
  dropdownEmptyContents: {
    display: "block",
    borderTop: "dt_divider",
    lineHeight: "10.2rem", // minHeight (128) - header height (26)
    fontWeight: 600,
    textAlign: "center",
    color: "dt_content_disabled",
  },
  requestIcon: {
    position: "relative",
    marginRight: "dt_s",
  },
};
